<template>
  <b-modal
    :id="modalId"
    ok-title="적용"
    cancel-title="취소"
    centered
    title="검색"
    size="lg"
    small
    @ok="ok()"
  >
    <b-card no-body>
      <b-row class="mb-2">
        <b-col cols="4" class="pr-0">
          <b-form-select
            v-model="selectedSearchCategory"
            :options="searchCategoryList"
          />
        </b-col>
        <b-col cols="5" class="pl-0">
          <b-input-group>
            <b-form-input
              v-model="searchText"
              placeholder="검색어"
            />
          </b-input-group>
        </b-col>
        <b-col cols="3" class="mr-0">
          <b-button
            class="ml-1"
            @click="search(1)"
          >
            검색
          </b-button>
        </b-col>
      </b-row>
      <b-table
        show-empty
        empty-text="검색 결과가 없습니다"
        :items="searchResult"
        :fields="searchResultColumns"
        class="mb-2"
        :busy="isBusy"
        responsive
      >
        <template #table-busy>
          <div class="text-center">
            <b-spinner
              variant="primary"
              label="API Loading..."
            />
            <div>
              <strong>Loading...</strong>
            </div>
          </div>
        </template>
        <template #cell(select)="data">
          <div style="width: 26px;">
            <a
              style="color:#258BFF;"
              @click="select(data.item)"
            >
              선택
            </a>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-if="searchResult.length > 0"
        v-model="currentPage"
        aria-controls="modal-center"
        :current-page="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
      />
      <b-row
        v-if="selectedItem"
      >
        <b-col cols="11" class="ml-1">
          선택 : {{ selectedItem.idx }}번
        </b-col>
      </b-row>
    </b-card>
  </b-modal>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'
import {
  BModal, BPagination, BCol, BRow,
  BTable, BCard, BFormSelect, BInputGroup,
  BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'

export default defineComponent({
  props: {
    modalId: String,
    searchUrl: String,
    searchCategoryList: Array,
    searchResult: Array,
    searchResultColumns: Array,
    totalRows: Number,
    isBusy: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    BModal,
    BPagination,
    BCol,
    BRow,
    BTable,
    BCard,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BButton,
    BSpinner,
  },
  setup(props, context) {
    const searchText = ref('')
    const perPage = 5
    const currentPage = ref(1)
    const selectedItem = ref()
    const selectedSearchCategory = ref('')
    selectedSearchCategory.value = props.searchCategoryList[0].value

    const ok = () => {
      context.emit('ok', selectedItem.value)
    }

    const select = item => {
      selectedItem.value = item
    }

    const search = page => {
      const queryParams = {}
      queryParams[selectedSearchCategory.value] = searchText.value
      if (page) {
        queryParams.page = page
      } else {
        queryParams.page = currentPage.value
      }
      queryParams.size = perPage
      context.emit('search', queryParams)
    }

    watch(currentPage, (newValue, oldValue) => {
      search()
    })

    return {
      searchText,
      perPage,
      currentPage,
      selectedItem,
      selectedSearchCategory,

      ok,
      select,
      search,
    }
  },
})
</script>

<style scoped>
td {
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
