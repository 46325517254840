<template>
<b-row>
    <b-col
      cols="12"
      sm="9"
      md="9"
      lg="10"
      xl="10"
    >
      <b-card
        title="마이스토어 생성"
      >
        <b-row class="mb-2">
          <b-col cols="9">
            <b-form-group
              label="마이스토어명"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="name"
                placeholder="마이스토어명"
              />
            </b-form-group>
            <b-form-group
              label="URL"
              label-for="url_path"
            >
              <b-form-input
                id="url_path"
                v-model="url_path"
                placeholder="URL  예시) tagby"
              />
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-group label="전화번호" label-for="inf_phone">
                  <b-form-input
                    id="inf_phone"
                    v-model="inf.phone"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="인플루언서 이름" label-for="inf_name">
                  <b-input-group>
                    <b-form-input
                      id="inf_name"
                      v-model="inf.name"
                      readonly
                    />
                    <b-input-group-append
                      v-b-modal.search-inf
                      class="cursor-pointer"
                    ><b-button variant="outline-secondary">검색</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- modal vertical center -->
            <SearchModal
              :modalId="'search-inf'"
              :searchCategoryList="searchCategoryList"
              :searchResult="searchResult"
              :searchResultField="searchResultField"
              :totalRows="totalRows"
              :isBusy="isBusy"
              @search="searchInf"
              @ok="changeInf"
            />
          </b-col>
          <b-col cols="2">
            <b-img
              height="206"
              width="206"
              class="d-inline-block"
              :src="profile_img_url"
              alt="썸네일이 없습니다."
              rounded
              stlye="max-height: 206px;"
            />
          </b-col>
        </b-row>
        <b-row class="m-1 mb-2">
          <label for="intro_text" style="font-size:16px;">마이스토어 소개글</label>
          <b-form-textarea
            id="intro_text"
            v-model="intro_text"
            rows="6"
            placeholder="마이스토어 소개글"
          />
        </b-row>
      </b-card>
    </b-col>
    <b-col
      cols="12"
      sm="3"
      md="3"
      lg="2"
      xl="2"
    >
      <b-card>
        <b-button
          variant="primary"
          class="mb-1"
          block
          :disabled="disableButton"
          @click="createMystore"
        >
          생성
        </b-button>
        <!-- 가상 input -->
        <input
          id="file"
          accept="image/*"
          type="file"
          style="display:none;"
          @change="uploadFile($event.target.files[0], 'PROFILE')"
        >
        <b-button
          class="mb-1"
          variant="primary"
          style="cursor: default;"
          block
        >
          <label for="file" style="height: 100%; color: white; cursor:pointer;">
            썸네일 바꾸기
          </label>
        </b-button>
      </b-card>
    </b-col>
</b-row>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'

import {
  BButton, BImg, BFormGroup, BFormInput,
  BCard, BRow, BCol, BInputGroup, BInputGroupAppend,
  BFormTextarea, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import axios from '@axios'
import router from '@/router'
import SearchModal from './components/SearchModal.vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroupAppend,
    BInputGroup,
    BFormTextarea,
    SearchModal,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  setup() {
    const inf = ref({
      idx: -1,
      phone: '',
      name: '',
    })
    const url_path = ref('')
    const name = ref('')
    const intro_text = ref('')
    const sns_list_json = ref('')
    const color = '#ffffff'
    const profile_img_url = ref('')
    const disableButton = ref(false)

    // search modal
    const searchCategoryList = [
      { text: '이름', value: 'name[]' },
      { text: '핸드폰번호', value: 'phone[]' },
    ]
    const searchResult = ref([])
    const searchResultField = [
      {
        key: 'idx',
        label: 'idx',
      },
      {
        key: 'name',
        label: '이름',
      },
      {
        key: 'phone',
        label: '핸드폰',
      },
      {
        key: 'select',
        label: '선택',
      },
    ]
    const totalRows = ref(0)
    const isBusy = ref(false)
    const searchInf = queryParams => {
      isBusy.value = true
      axios.get(
        `${process.env.VUE_APP_BACKEND_SERVER}/manage/influencer/list/`,
        { params: queryParams },
      ).then(res => {
        searchResult.value = res.data.data
        totalRows.value = res.data.page_info.total_count
      }).catch(err => {
        console.log(err.data)
        alert('인플루언서 정보를 불러오지 못하였습니다.')
      }).finally(
        isBusy.value = false
      )
    }

    const changeInf = infData => {
      inf.value.idx = infData.idx
      inf.value.name = infData.name
      inf.value.phone = infData.phone
    }

    const createMystore = () => {
      disableButton.value = true

      // validate
      if (!inf.value.idx === -1 || !name.value || !url_path.value) {
        alert('필수 정보가 비어있습니다.')
        return;
      }

      const body = {
        inf_idx_id: inf.value.idx,
        name: name.value,
        url_path: url_path.value,
        intro_text: intro_text.value,
        profile_img_url: profile_img_url.value,
        color,
      }

      axios.post(
        `${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/mystore/create/`,
        body,
      ).then(() => {
        alert('마이스토어를 성공적으로 생성하였습니다.')
        router.push({ name: 'commerce-mystore-list' })
      }).catch(error => {
        console.log(error.response.data)
        alert('마이스토어 생성을 실패하였습니다.')
      }).finally(() => {
        disableButton.value = false
      })
    }

    const uploadFile = async (imgFile, purpose) => {
      const file_info = {
        name: imgFile.name,
        type: imgFile.type,
        size: imgFile.size,
      }
      const formData = new FormData()
      formData.append('file', imgFile)
      formData.append(
        'file_info',
        JSON.stringify(file_info),
      )
      formData.append('purpose', purpose)
      const infoTable = 'myshop_info'
      let url = `${process.env.VUE_APP_ASYNC_BACKEND_SERVER}/file/upload/${infoTable}/`
      const result = await axios.post(url, formData)
        .then(res => {
          const img_url = res.data.result.file_info.file_url
          profile_img_url.value = img_url
          alert("성공적으로 이미지를 업로드했습니다.")
          return img_url
        }).catch(err => {
          alert("이미지 업로드에 실패했습니다.")
          console.log(err.data)
          return ''
        })
      return result
    }


    return {
      inf,
      url_path,
      name,
      intro_text,
      color,
      profile_img_url,
      disableButton,
      createMystore,
      uploadFile,

      // modal
      searchCategoryList,
      searchResult,
      searchResultField,
      totalRows,
      isBusy,
      searchInf,
      changeInf,
    }
  },
})
</script>

<style>

</style>